<template>
    <div class="wrapper has_nav_bar">
        <van-nav-bar title="消息中心" @click-left="goHome" left-text="返回" left-arrow fixed/>
        <div class="content">
            <div class="main-content">
                <div class="message-list">
                    <h2 class="van-cell-group-title">消息列表<span class="extra-info">共{{ total }}条消息</span></h2>
                    <van-list
                        v-model="loading"
                        :finished="finished"
                        finished-text="没有更多了"
                        @load="onLoad"
                    >
                        <div class="message-block" v-for="item in dataList" v-if="dataList!=null ">
                            <div class="message-title">
                                <span>{{ item.title }}</span>
                                <van-tag v-if="item.status === '未读'"  plain type="warning">{{ item.status }}</van-tag>
                                <van-tag v-else plain >{{ item.status }}</van-tag>
                            </div>

                            <div class="message-misc">
                                <div class="message-info">
                                    <div class="message-content">{{ item.content.substring(0,50) + '...' }}</div>
                                    <div class="message-time">{{ item.sendTime }}</div>
                                </div>
                                <div class="apply-btn">
                                    <van-button type="primary" size="small" @click="readMessage(item)">查看</van-button>
                                </div>
                            </div>
                        </div>
                    </van-list>
                </div>
            </div>
        </div>
        <div class="footer">
        </div>
    </div>
</template>

<script>
import {getProjectByCode, reqEditStatus, reqMessagePage} from "../../api/client-api";
import {Dialog} from "vant";

export default {
    data() {
        return {
            viewForm: {},
            projectCode: null,
            showReadMessage: false,
            loading: false,
            finished: false,
            pageSize: 10,
            pageNum: 1,
            total: 0,
            dataList: [],
        }
    },
    methods: {
        readMessage(item) {
            if (item.status === '未读') {
                let params = {
                    status: '已读',
                    id: item.id
                }
                reqEditStatus(params).then((res) => {
                    if (res.data.code === 1) {
                        Dialog.alert({
                            title: item.title,
                            message: item.content + "\n" + item.sendTime,
                        }).then(() => {
                            this.pageSize = 10, this.pageNum = 1, this.total = 0, this.dataList = [], this.getMessage()
                        });
                    }
                }).catch(
                )
            } else {
                Dialog.alert({
                    title: item.title,
                    message: "<div class='text'>" + item.content + "</div><div class='date'>" + item.sendTime + "</div>",
                }).then(() => {
                });
            }

        },
        getMessage() {
            if (sessionStorage.getItem('Authorization')) {
                this.loading = true;
                let para = {
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                };
                reqMessagePage(para).then((res) => {
                    if (this.pageNum === 1) {
                        this.total = res.data.data.total
                        this.dataList = res.data.data.records
                    } else {
                        this.dataList = this.dataList.concat(res.data.data.records)
                    }
                    this.loading = false;
                    this.pageNum++;
                    if (res.data.data.records.length === 0 || res.data.data.records === null || res.data.data.records.length < 1) {
                        this.finished = true
                        return
                    }
                }).catch(() => {
                    this.loading = false;
                    this.dataList = [];
                })
            } else {
                let redirectTo = window.location.href;
                sessionStorage.setItem('redirectTo', redirectTo);
                this.$router.push({name: 'login', query: {projectCode: this.projectCode}});
            }
        },
        getProject() {
            getProjectByCode({code: this.projectCode}).then((res) => {
                if (res.data.data) {
                    this.viewForm = res.data.data;
                }
            }).catch((err) => {
                console.log('err===', err)
            })
        },
        goHome() {
            this.$router.push({name: 'project', params: {id: this.projectCode}});
        },
        onLoad() {
            this.getMessage()
        }
    },
    mounted() {
        this.projectCode = this.$route.query.projectCode;
        this.getProject()
    }
};
</script>

<style scoped>

</style>
