<template>
    <div class="wrapper">
        <Header :code="projectCode"></Header>
        <div class="content">
            <div class="main-content">
                <div class="breadcrumb">
                    <div class="tips">共收到 {{ total }} 条消息</div>
                    <Breadcrumb>
                        <BreadcrumbItem @click.native="goHome(projectCode)">主页</BreadcrumbItem>
                        <BreadcrumbItem>消息中心</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <div class="my-apply">
                    <Table stripe :columns="columns" :data="dataList" :loading="loading">
                        <template slot-scope="{ row, index }" slot="action">
                            <a @click="handleView(row, index)">查看详情</a>
                        </template>
                    </Table>
                </div>
                <div class="table-footer">
                    <Row>
                        <Col class="total" span="6">共 {{ total }} 条记录 第 {{ pageNum }}/{{ pages }} 页</Col>
                        <Col class="pagenation" span="18">
                            <Page :page-size="pageSize" :total="total" :current="pageNum"
                                  :page-size-opts="[15, 20, 50, 100]" @on-change="changePage"
                                  @on-page-size-change="changPageSize" show-sizer show-elevator/>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
        <read-message v-model="showReadMessage" :id="id" :content="content" :sendTime="sendTime" :title="title" v-on:getMessage="getMessage"></read-message>
        <Footer></Footer>
    </div>
</template>

<script>
import {reqMessagePage} from "@/api/client-api";
import Header from "../../components/pc/Header"
import Footer from "../../components/pc/Footer"
import readMessage from "../pc/readMessage";
import {getProjectByCode, reqEditStatus} from "../../api/client-api";

export default {
    components: {
        readMessage: readMessage,
        Header: Header,
        Footer: Footer
    },
    data() {
        return {
            id: '',
            content: '',
            sendTime: '',
            title: '',
            viewForm: {},
            projectCode: null,
            showReadMessage: false,
            loading: false,
            dataList: [],
            total: 0,
            pages: 1,
            pageSize: 20,
            pageNum: 1,
            columns: [
                {
                    title: '消息标题',
                    key: 'title',
                    width: 150,
                },
                {
                    title: '消息内容',
                    key: 'content',
                    width: 600,
                    ellipsis: true,
                },
                {
                    title: '发送时间',
                    key: 'sendTime',
                    width: 200,
                },
                {
                    title: '状态',
                    key: 'status',
                    width: 95,
                    render: (h, params) => {
                        return h('span', [
                            h(
                                'span',
                                {
                                    style: {
                                        color:
                                            params.row.status === '已读' ? '' : '#ff9900',
                                    },
                                },
                                params.row.status
                            ),
                        ])
                    },
                },
                {
                    title: '操作',
                    slot: 'action',
                    width: 100,
                    align: 'center',
                },
            ]
        }
    },
    methods: {
        getMessage() {
            this.loading = true;
            this.dataList = [];
            let para = {
                pageNum: this.pageNum,
                pageSize: this.pageSize,
            };
            reqMessagePage(para).then((res)=>{
                this.loading = false;
                this.pageNum = res.data.data.current
                this.pages = res.data.data.pages
                this.total = res.data.data.total
                this.dataList = res.data.data.records;
            }).catch(()=>{
                this.loading = false;
                this.dataList = [];
            })
        },
        handleView(row, index) {
            this.id = row.id;
            this.title = row.title;
            this.content = row.content;
            this.sendTime = row.sendTime;
            if (row.status !== '已读'){
                let params = {
                    status: '已读',
                    id: this.id
                }
                reqEditStatus(params).then((res)=>{
                    if (res.data.code === 1) {
                        this.getMessage()
                        this.showReadMessage = true;
                    }
                }).catch(
                )
            } else {
                this.showReadMessage = true
            }
        },
        getProject() {
            getProjectByCode({code: this.projectCode}).then((res)=>{
                if (res.data.data) {
                    this.viewForm = res.data.data;
                }
            }).catch((err)=>{
                console.log('err===', err)
            })
        },
        goHome(code) {
            this.$router.push({name:'project', params:{id: code}});
        },
        //分页点击，切换页面
        changePage(val) {
            this.pageNum = val;
            this.getMessage();
        },
        //每页显示的数据条数
        changPageSize(numOfRows) {
            //实时获取当前需要显示的条数
            this.pageSize = numOfRows;
            this.getMessage();
        },
    },
    mounted() {
        this.projectCode = this.$route.query.projectCode;
        this.getProject()
        this.getMessage()
    }
};
</script>

<style scoped>

</style>

